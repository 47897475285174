// tslint:disable
import * as EntityClasses from "@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g";

/**
 * ShipmentTrackingLines entity interface.
 */
export interface IShipmentTrackingLines {
  TrackingLine?: ITrackingLine[];
}

/**
 * ProductReferenceNumber entity interface.
 */
export interface IProductReferenceNumber {
  Id: number;
  ReferenceNumber?: string;
}

/**
 * InvoiceInquiryResult entity interface.
 */
export interface IInvoiceInquiryResult {
  EmailSent: boolean;
  Message?: string;
}

/**
 * TrackingLine entity interface.
 */
export interface ITrackingLine {
  LineNumber?: string;
  TrackingId?: string;
  TrackingUrl?: string;
}

/**
 * ShipmentTrackingLines entity class.
 */
export class ShipmentTrackingLinesExtensionClass
  implements IShipmentTrackingLines
{
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public TrackingLine: ITrackingLine[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.TrackingLine = undefined;
    if (odataObject.TrackingLine) {
      this.TrackingLine = [];
      for (var i = 0; i < odataObject.TrackingLine.length; i++) {
        if (odataObject.TrackingLine[i]) {
          if (odataObject.TrackingLine[i]["@odata.type"]) {
            var className: string = odataObject.TrackingLine[i]["@odata.type"];
            className = className
              .substr(className.lastIndexOf(".") + 1)
              .concat("Class");
            if (EntityClasses[className]) {
              this.TrackingLine[i] = new EntityClasses[className](
                odataObject.TrackingLine[i]
              );
            }
          } else {
            this.TrackingLine[i] = new TrackingLineExtensionClass(
              odataObject.TrackingLine[i]
            );
          }
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.TrackingLine[i] = undefined;
        }
      }
    }
  }
}

/**
 * ProductReferenceNumber entity class.
 */
export class ProductReferenceNumberExtensionClass
  implements IProductReferenceNumber
{
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Id: number;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ReferenceNumber: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Id = odataObject.Id ? parseInt(odataObject.Id, 10) : 0;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ReferenceNumber = odataObject.ReferenceNumber;
  }
}

/**
 * InvoiceInquiryResult entity class.
 */
export class InvoiceInquiryResultExtensionClass
  implements IInvoiceInquiryResult
{
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public EmailSent: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Message: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.EmailSent = odataObject.EmailSent;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Message = odataObject.Message;
  }
}

/**
 * TrackingLine entity class.
 */
export class TrackingLineExtensionClass implements ITrackingLine {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public LineNumber: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public TrackingId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public TrackingUrl: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.LineNumber = odataObject.LineNumber;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.TrackingId = odataObject.TrackingId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.TrackingUrl = odataObject.TrackingUrl;
  }
}
